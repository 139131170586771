import React, { useState } from 'react';

function OrderForm() {
    // Step values
    const [step1Value, setStep1Value] = useState('');
    const [step2Value, setStep2Value] = useState('');
    const [step3Value, setStep3Value] = useState('');

    // Step visibility
    const [isStep1Completed, setIsStep1Completed] = useState(false);
    const [isStep2Completed, setIsStep2Completed] = useState(false);

    const handleStep1Save = () => {
        if (step1Value.trim()) {
            setIsStep1Completed(true);
        } else {
            alert('Please fill out Step 1.');
        }
    };

    const handleStep2Save = () => {
        if (step2Value.trim()) {
            setIsStep2Completed(true);
        } else {
            alert('Please fill out Step 2.');
        }
    };

    const handleFormSubmit = () => {
        if (step3Value.trim()) {
            alert('Form submitted!');
        } else {
            alert('Please fill out Step 3.');
        }
    };

    return (
        <div>
            <h1>Order Form</h1>

            {/* Step 1 */}
            <div>
                <h2>Step 1</h2>
                <input
                    type="text"
                    value={step1Value}
                    onChange={(e) => setStep1Value(e.target.value)}
                    placeholder="Enter value for Step 1"
                />
                <button onClick={handleStep1Save}>Save Step 1</button>
            </div>

            {/* Step 2 */}
            {isStep1Completed && (
                <div>
                    <h2>Step 2</h2>
                    <input
                        type="text"
                        value={step2Value}
                        onChange={(e) => setStep2Value(e.target.value)}
                        placeholder="Enter value for Step 2"
                    />
                    <button onClick={handleStep2Save}>Save Step 2</button>
                </div>
            )}

            {/* Step 3 */}
            {isStep2Completed && (
                <div>
                    <h2>Step 3</h2>
                    <input
                        type="text"
                        value={step3Value}
                        onChange={(e) => setStep3Value(e.target.value)}
                        placeholder="Enter value for Step 3"
                    />
                    <button onClick={handleFormSubmit}>Submit Order</button>
                </div>
            )}
        </div>
    );
}

export default OrderForm;
