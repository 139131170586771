import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Instructions from './pages/Instructions';
import FourOhFour from './pages/404';
import EventCalendar from './components/Events';
import News from './components/News';
import Parties from './pages/Parties';
import Reservation from './reservation/Reservation';
import Waitlist from './reservation/Waitlist';
import ProtectedRoute from './components/auth/ProtectedRoute';
import LoginPage from './components/auth/LoginPage';
import { AuthProvider } from './components/auth/AuthContext';
import Seats from './reservation/Seats';
import MarketSystem from './reservation/System';
// import ToGoForm from './reservation/ToGo';
import OrderForm from './pages/Reserve';

export const List = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/instructions' element={<Instructions />} />
          <Route path='/news' element={<News />} />
          <Route path='/events' element={<EventCalendar />} />
          <Route path="*" element={<FourOhFour />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/parties" element={<Parties />} />
          <Route path="/r" element={<ProtectedRoute component={Reservation} />} />
          <Route path="/w" element={<ProtectedRoute component={Waitlist} />} />
          <Route path="/s" element={<ProtectedRoute component={Seats} />} />
          <Route path="/dwd" element={<ProtectedRoute component={MarketSystem}/>} />
          {/* <Route path='/tg' element={<ToGoForm />} /> */}
          <Route path="reserve" element={<OrderForm />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}
